import { ethers } from "ethers";
import { tokenABI } from "./ABI/tokenABI";
import { routerABI } from "./ABI/routerABI";
import { getSwapContractAddresses } from "./contractAddresses";
import { factoryABI } from "./ABI/factoryABI";
import { Web3 } from "web3";
import BN from "bn.js"; // Import BN directly

export const NATIVE = "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE";
export const ZERO = "0x0000000000000000000000000000000000000000";

export async function swapCrypto(
  chainId,
  privateKey,
  path,
  amountIn,
  decimal,
  receiverAddress
) {
  try {
    console.log("Input Parameters :", {
      chainId,
      path,
      amountIn,
      decimal,
    });

    // const privateKey =
    //   "0x5cf7c4ffd4a53f639ce348d31944c70b545b82bad831e622c88997f5e08cff1a";

    const Tokens = getSwapContractAddresses(chainId);
    const Network = await getNetwork(chainId);
    const web3 = new Web3(Network.rpc);
    // Create wallet from private key
    const account = web3.eth.accounts.privateKeyToAccount(privateKey);
    web3.eth.accounts.wallet.add(account);

    const amountsInWei = web3.utils.toWei(amountIn, decimal);

    const isNativeSwap =
      path[0].toLowerCase() === NATIVE.toLowerCase() ||
      path[1].toLowerCase() === NATIVE.toLowerCase();

    console.log(
      isNativeSwap ? "Swap with native tokens ... !" : "Swapping with ERC20 ..."
    );

    const tokenContract = new web3.eth.Contract(tokenABI, path[0]);
    const approvedAmount = await tokenContract.methods
      .allowance(account.address, Tokens.router)
      .call();

    // Need to approve the contract address
    if (new BN(approvedAmount).lt(new BN(amountsInWei))) {
      console.log(
        `Approved amount: ${approvedAmount.toString()}, Amount needed to approve at least: ${amountsInWei.toString()}`
      );
      // Approve the router to spend the tokens
      const approveTx = tokenContract.methods
        .approve(Tokens.router, amountsInWei)
        .send({ from: account.address });

      const receipt = await approveTx;
      console.log("Receipt:", receipt);
      console.log("Transaction approved successfully!");
    } else {
      console.log("Don't need to approve the contract address");
    }

    const swapContract = new web3.eth.Contract(routerABI, Tokens.router);

    if (!isNativeSwap) {
      // Swap Token to Token
      const swapTokenReceipt = await swapContract.methods
        .swapExactTokensForTokens(
          amountsInWei,
          0,
          path,
          receiverAddress,
          Math.floor(Date.now() / 1000) + 60 * 10
        )
        .send({
          from: account.address,
          gas: 1000000, // Adjust the gas limit as needed
        });

      console.log(
        "Swap Token 2 Token  transaction confirmed:",
        swapTokenReceipt
      );
      return swapTokenReceipt;
    } else {
      // Swap Token to Native

      const txNativeSwap = await swapContract.methods
        .swapExactTokensForETH(
          amountsInWei,
          path,
          receiverAddress,
          Math.floor(Date.now() / 1000) + 60 * 10
        )
        .send({
          from: account.address,
          gas: 1000000, // Adjust the gas limit as needed
        });

      // const swapReceipt = await txSwap;
      console.log("Swap Token 2 Native transaction confirmed:", txNativeSwap);
      return txNativeSwap;
    }
  } catch (e) {
    console.error("Error in swapCrypto:", e);
    return null;
  }
}

export async function evmCryptoBalanceCheck(
  chainId,
  tokenAddress,
  walletAddress,
  decimal
) {
  try {
    const Network = await getNetwork(Number(chainId));

    const provider = new ethers.providers.JsonRpcProvider(Network.rpc);

    if (tokenAddress?.toLowerCase() === NATIVE.toLowerCase()) {
      const balance = await provider.getBalance(walletAddress);
      const formattedBalance = Number(ethers.utils.formatEther(balance));
      return formattedBalance ?? 0;
    } else {
      const tokenContract = new ethers.Contract(
        tokenAddress,
        tokenABI,
        provider
      );

      let balance = 0;
      try {
        balance = await tokenContract.balanceOf(walletAddress);
      } catch (e) {}

      return ethers.utils.formatUnits(balance, decimal) ?? 0;
    }
  } catch (e) {
    console.error(
      "Error in balance fetch:",
      chainId,
      tokenAddress,
      walletAddress,
      e
    );
    return 0;
  }
}

// okay

export function getNetwork(chainId) {
  switch (Number(chainId)) {
    case 11155111:
      return {
        network: "Ethereum",
        symbol: "ETH",
        rpc: "https://eth-sepolia.g.alchemy.com/v2/HZGTgTPiqB408bYkAdUFeDOTedqp4DBA",
        explorerURL: "https://sepolia.etherscan.io/tx/",
        tokenType: "ERC20",
      };
    case 97:
      return {
        network: "BNB Smart Chain",
        symbol: "BNB",
        rpc: "https://data-seed-prebsc-1-s1.bnbchain.org:8545",
        explorerURL: "https://testnet.bscscan.com/tx/",
        tokenType: "BEP20",
      };
    case 80002:
      return {
        network: "Polygon",
        symbol: "MATIC",
        rpc: "https://polygon-amoy.g.alchemy.com/v2/HZGTgTPiqB408bYkAdUFeDOTedqp4DBA",
        explorerURL: "https://amoy.polygonscan.com/tx/",
      };

    case 43113:
      return {
        network: "Avalanche-Fuji",
        symbol: "AVAX",
        rpc: "https://avax-fuji.g.alchemy.com/v2/HZGTgTPiqB408bYkAdUFeDOTedqp4DBA",
        explorerURL: "https://testnet.avascan.info/blockchain/pulsar/tx/",
      };
    default:
      throw new Error("Unsupported chainId");
  }
}

export async function getNativeTransferTxFee(chainId, transactionDetails) {
  try {
    const Network = getNetwork(chainId);
    console.log("get RPC URL: ", Network);
    const provider = new ethers.providers.JsonRpcProvider(Network.rpc);

    // Get current gas price
    const gasPrice = await provider.getGasPrice();
    console.log("Current gas price (wei): ", gasPrice.toString());

    // Estimate gas limit for the provided transaction details
    const gasLimit = await provider.estimateGas(transactionDetails);
    console.log("Estimated gas limit: ", gasLimit.toString());

    // Calculate the transaction fee
    const txFee = gasPrice.mul(gasLimit);
    const formattedTxFee = ethers.utils.formatEther(txFee);
    console.log("Estimated transaction fee (ETH): ", formattedTxFee);

    return formattedTxFee;
  } catch (e) {
    console.error("Error in transaction fee estimation:", e);
  }
}

// Function to get transaction fee for a smart contract function call
// export async function getERC20TransferTxFee(
//   chainId,
//   contractAddress,
//   receiverAddress,
//   amount
// ) {
//   try {
//     console.log(
//       "Chain ID, Contract Address, Receiver Address, Amount: ",
//       chainId,
//       contractAddress,
//       receiverAddress,
//       amount
//     );

//     // Get the network configuration
//     const Network = getNetwork(chainId);
//     const provider = new ethers.providers.JsonRpcProvider(Network.rpc);

//     // Initialize the contract instance with ABI and provider
//     const contract = new Contract(contractAddress, tokenABI, provider);
//     console.log("Contract: ", contract);

//     // Get current gas price
//     const gasPrice = await provider.getGasPrice();
//     console.log("Current gas price (wei): ", gasPrice.toString());

//     // Convert the amount to the appropriate format for the token (usually 18 decimals)
//     const amountInWei = ethers.utils.parseUnits(amount.toString(), 18);
//     console.log("Amount in wei: ", amountInWei.toString());

//     // Estimate the gas limit required for the transaction
//     const gasLimit = await contract.estimateGas.transfer(
//       receiverAddress,
//       amountInWei,
//       {
//         gasPrice: gasPrice,
//       }
//     );
//     console.log("Estimated gas limit: ", gasLimit.toString());

//     // Calculate the transaction fee
//     const txFee = gasPrice.mul(gasLimit);
//     const formattedTxFee = ethers.utils.formatEther(txFee);
//     console.log("Estimated transaction fee (ETH): ", formattedTxFee);

//     return formattedTxFee;
//   } catch (e) {
//     console.error("Error in transaction fee estimation:", e);
//   }
// }

export async function getERC20TransferTxFee(
  chainId,
  contractAddress,
  receiverAddress,
  amount,
  decimal
) {
  try {
    const Network = getNetwork(chainId);
    const web3 = new Web3(Network.rpc);
    const contract = new web3.eth.Contract(tokenABI, contractAddress);

    // Get current gas price
    const gasPrice = await web3.eth.getGasPrice();
    const amountInWei = web3.utils.toWei(amount.toString(), "ether"); // Assuming the token has 18 decimals
    const gasLimit = await contract.methods
      .transfer(receiverAddress, amountInWei)
      .estimateGas({ from: receiverAddress });
    console.log("Estimated gas limit: ", gasLimit);

    // Calculate the transaction fee
    const txFee = Number(gasPrice) * Number(gasLimit);
    const formattedTxFee = web3.utils.fromWei(txFee.toString(), "ether");
    console.log("Estimated transaction fee (ETH): ", formattedTxFee);

    return formattedTxFee;
  } catch (e) {
    console.error("Error in transaction fee estimation:", e);
    return null;
  }
}

export async function evmCryptoTransfer(
  chainId,
  privateKey,
  tokenAddress,
  receiverAddress,
  amount
) {
  try {
    const Network = getNetwork(chainId);
    const web3 = new Web3(Network.rpc);

    // Create a wallet from the private key
    const account = web3.eth.accounts.privateKeyToAccount(privateKey);
    web3.eth.accounts.wallet.add(account);

    if (tokenAddress === NATIVE) {
      console.log("Transferring native token");

      const tx = {
        to: receiverAddress,
        value: web3.utils.toWei(amount.toString(), "ether"),
        gas: 21000, // Adjust the gas limit as needed
        from: account.address,
      };

      const transactionReceipt = await web3.eth.sendTransaction(tx);
      console.log(`Transaction hash: ${transactionReceipt.transactionHash}`);

      console.log(
        `Transaction mined in block ${transactionReceipt.blockNumber}`
      );

      return transactionReceipt;
    } else {
      console.log("Transferring ERC20 Rahul...");

      try {
        const tokenContract = new web3.eth.Contract(tokenABI, tokenAddress);
        const AMOUNT = web3.utils.toWei(amount.toString(), "ether"); // "ether" is used for 18 decimals, adjust if needed for your token

        // Estimate gas for the transaction
        const gasEstimate = await tokenContract.methods
          .transfer(receiverAddress, AMOUNT)
          .estimateGas({ from: account.address });

        console.log(`Estimated Gas: ${gasEstimate}`);

        // Use 'pending' to get the next correct nonce, accounting for unconfirmed transactions
        const nonce = await web3.eth.getTransactionCount(
          account.address,
          "pending"
        );

        // Sending the transaction with the estimated gas
        const receipt = await tokenContract.methods
          .transfer(receiverAddress, AMOUNT)
          .send({ from: account.address, gas: gasEstimate, nonce: nonce });

        console.log(`Transaction hash: ${receipt.transactionHash}`);
        return receipt;
      } catch (error) {
        console.error("Error during ERC20 transfer:", error);
      }
    }
  } catch (e) {
    console.error("Error in evmCryptoTransfer:", e);
    return null;
  }
}

// export async function evmCryptoTransfer(
//   chainId,
//   privateKey,
//   tokenAddress,
//   receiverAddress,
//   amount
// ) {
//   try {
//     const Network = getNetwork(chainId);
//     const provider = new ethers.providers.JsonRpcProvider(Network.rpc);

//     const wallet = new ethers.Wallet(privateKey, provider);

//     if (tokenAddress === NATIVE) {
//       console.log("Transfering native token");
//       const tx = {
//         to: receiverAddress,
//         value: ethers.utils.parseEther(amount.toString()),
//       };

//       const transactionResponse = await wallet.sendTransaction(tx);
//       console.log(`Transaction hash: ${transactionResponse.hash}`);

//       // Wait for the transaction to be mined
//       const receipt = await transactionResponse.wait();
//       console.log(`Transaction mined in block ${receipt.blockNumber}`);

//       return receipt;
//     } else {
//       console.log("Transfer ERC20 ...");
//       const tokenContract = new ethers.Contract(tokenAddress, tokenABI, wallet);
//       const AMOUNT = ethers.utils.parseUnits(amount.toString(), 18); // Replace "1.0" with the amount of tokens to transfer and "18" with the token's decimal places
//       const tx = await tokenContract.transfer(receiverAddress, AMOUNT);
//       console.log(`Transaction hash: ${tx.hash}`);
//       const receipt = await tx.wait();
//       return receipt;
//     }
//   } catch (e) {
//     console.error("Error in evmCryptoTransfer:", e, typeof e);
//     return null;
//   }
// }

export async function isSwapPair(chainId, tokenA, tokenB, amountIn, decimal) {
  let data = {
    result: false,
    amountOut: 0,
  };

  try {
    console.log(
      "chainId, TokenA and TokenB : ",
      chainId,
      tokenA,
      tokenB,
      amountIn,
      decimal
    );

    const Tokens = getSwapContractAddresses(chainId);
    const Network = await getNetwork(chainId);
    const provider = new ethers.providers.JsonRpcProvider(Network.rpc);

    const tokenContract = new ethers.Contract(
      Tokens.factory,
      factoryABI,
      provider
    );

    const swapContract = new ethers.Contract(
      Tokens.router,
      routerABI,
      provider
    );

    const isPair = await tokenContract.getPair(tokenA, tokenB);

    console.log("isPair : ", isPair);
    if (isPair.toLowerCase() === ZERO.toLocaleLowerCase()) {
      return data;
    } else {
      const qoute = await swapContract.getAmountsOut(amountIn.toString(), [
        tokenA,
        tokenB,
      ]);
      console.log("Qoute is : ", qoute);
      data.result = true;
      let _amountOut = Number(qoute[1]._hex);
      console.log("_amountOut : ", _amountOut, decimal);

      const web3 = new Web3(Network.rpc);

      const amountsOutWei = await web3.utils.fromWei(_amountOut, decimal);

      console.log("----------- amountsOutWei : ", amountsOutWei);

      data.amountOut = amountsOutWei;
      return data;
    }
  } catch (e) {
    console.log("Error in isSwapPair : ", e);
    return data;
  }
}
